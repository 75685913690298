import { Form } from "@/modules/common/components";
import { Loader as RootLoader } from "@/root";
import { useForm } from "@conform-to/react";
import { getZodConstraint, parseWithZod } from "@conform-to/zod";
import { useFetcher, useLocation, useRouteLoaderData } from "@remix-run/react";
import { useEffect } from "react";
import { z } from "zod";

const postSchema = z.object({
  action: z.union([z.literal("accept"), z.literal("reject")]),
});

export function Analytics() {
  const root = useRouteLoaderData<RootLoader>("root");
  const location = useLocation();
  const fetcher = useFetcher();

  const [form] = useForm({
    shouldValidate: "onBlur",
    shouldRevalidate: "onInput",
    constraint: getZodConstraint(postSchema),
    onValidate: ({ formData }) => {
      return parseWithZod(formData, { schema: postSchema });
    },
  });

  // Google Analytics
  useEffect(() => {
    if (!root?.env.GA_MEASUREMENT_ID) return;

    window.gtag("config", root.env.GA_MEASUREMENT_ID, {
      page_path: location.pathname,
    });
  }, [root, location]);

  return (
    <>
      {root?.env.GA_MEASUREMENT_ID && (
        <>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${root.env.GA_MEASUREMENT_ID}`}
          />
          <script
            async
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${root.env.GA_MEASUREMENT_ID}', {
                  page_path: window.location.pathname,
                });
              `,
            }}
          />
        </>
      )}

      {root?.analytics == null && (
        <div className="fixed border border-common-border z-20 bg-white rounded-xl shadow-soft bottom-4 right-4 left-4 sm:right-auto sm:max-w-[330px] p-3">
          <p className="text-sm">
            We use cookies (and other similar technologies) to improve your
            experience on our site.
          </p>

          <Form
            method="post"
            action="/analytics"
            form={form}
            fetcher={fetcher}
            className="grid grid-cols-2 gap-2 mt-2"
          >
            <Form.Button
              value="accept"
              variant="primary"
              isLoading={fetcher.state != "idle"}
              className="text-xs h-[34px]"
            >
              Accept All
            </Form.Button>

            <Form.Button
              value="reject"
              variant="default"
              isLoading={fetcher.state != "idle"}
              className="text-xs h-[34px]"
            >
              Reject All
            </Form.Button>
          </Form>
        </div>
      )}
    </>
  );
}
