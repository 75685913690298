import { useNavigation } from "@remix-run/react";
import NProgress from "nprogress";
import { MutableRefObject, useEffect, useRef } from "react";

export function Progress() {
  const navigation = useNavigation();
  const timer: MutableRefObject<NodeJS.Timeout | null> = useRef(null);

  const start = () => {
    timer.current = setTimeout(() => {
      NProgress.start();
    }, 250);
  };

  const stop = () => {
    clearTimeout(timer.current as NodeJS.Timeout);
    NProgress.done();
  };

  useEffect(() => {
    NProgress.configure({ showSpinner: false });

    if (
      ["loading", "submitting"].includes(navigation.state) &&
      [undefined, "GET"].includes(navigation.formMethod)
    ) {
      start();
    } else {
      stop();
    }
  }, [navigation.state, navigation.formMethod]);

  return null;
}
