import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { Analytics } from "@/components";
import { Header, Layout, Main } from "@/components/layout";
import config from "@/config.json";
import { AuthService } from "@/modules/auth/services";
import { Anchor, Flash, Progress } from "@/modules/common/components";
import { verifyDomainMiddlewere } from "@/modules/common/middlewares";
import { AnalyticsService, SupportService } from "@/modules/common/services";
import styles from "@/tailwind.css?url";
import {
  HeadersFunction,
  json,
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import { useMemo } from "react";
import { getToast } from "remix-toast";

export type Loader = typeof loader;

export const links: LinksFunction = () => [
  {
    rel: "stylesheet",
    href: styles,
  },
  {
    rel: "icon",
    href: "/images/favicon.png",
  },
  {
    rel: "apple-touch-icon",
    href: "/images/favicon.png",
  },
];

export const headers: HeadersFunction = () => ({
  "X-Frame-Options": "DENY",
  "Content-Security-Policy": "frame-ancestors 'none'",
});

export const loader = async (args: LoaderFunctionArgs) => {
  await verifyDomainMiddlewere(process.env.APP_URL, args);

  const toast = await getToast(args.request);

  return json(
    {
      env: {
        APP_URL: process.env.APP_URL,
        MEDIA_URL: process.env.MEDIA_URL,

        SEZNAM_WMT: process.env.SEZNAM_WMT,
        SENTRY_DSN: process.env.SENTRY_DSN,
        TURNSTILE_SITE_KEY: process.env.TURNSTILE_SITE_KEY,
        GA_MEASUREMENT_ID: process.env.GA_MEASUREMENT_ID,
      },
      toast: toast.toast,
      user: await AuthService.handle(args),
      support: await SupportService.handle(args),
      analytics: await AnalyticsService.handle(args),
    },
    {
      headers: toast.headers,
    }
  );
};

export default withSentry(function App() {
  const data = useLoaderData<Loader>();

  return (
    <html lang="en" className={data.support.classes}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="HandheldFriendly" content="true" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="theme-color" content={config.color} />
        <meta name="seznam-wmt" content={data.env.SEZNAM_WMT} />
        <Meta />
        <Links />
      </head>
      <body className="min-h-screen text-base antialiased bg-white text-common-text">
        <Progress />
        <Flash position="bottom-center" />
        <Outlet />
        <Analytics />
        <ScrollRestoration />
        <Scripts />
        <script
          async
          dangerouslySetInnerHTML={{
            __html: `window.env = ${JSON.stringify(data.env)}`,
          }}
        />
        <script
          async
          src="https://challenges.cloudflare.com/turnstile/v0/api.js"
        />
      </body>
    </html>
  );
});

export function ErrorBoundary() {
  const error = useRouteError();

  const title = useMemo(() => {
    if (isRouteErrorResponse(error)) {
      return error.status;
    }

    return "500";
  }, [error]);

  const description = useMemo(() => {
    if (isRouteErrorResponse(error)) {
      return `${error.status} ${error.statusText}`;
    }

    return "Internal Server Error";
  }, [error]);

  captureRemixErrorBoundaryError(error);

  return (
    <html lang="en">
      <head>
        <title>{`${title} • ${config.name}`}</title>
        <meta name="robots" content="noindex, nofollow" />
        <Meta />
        <Links />
      </head>
      <body className="min-h-screen font-sans text-base antialiased bg-white text-common-text">
        <Layout>
          <Header />

          <Main className="items-center justify-center">
            <div className="max-w-screen-md text-center">
              <h1 className="mb-4 text-4xl font-bold text-center text-common-title">
                {description}
              </h1>

              <p>
                <Anchor to="/" variant="link">
                  Return to Homepage
                </Anchor>
              </p>
            </div>
          </Main>
        </Layout>
        <Scripts />
      </body>
    </html>
  );
}
