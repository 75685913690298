import { Loader } from "@/root";
import { useLoaderData } from "@remix-run/react";
import { ComponentProps, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";

type Props = ComponentProps<typeof Toaster>;

export function Flash(props: Props) {
  const data = useLoaderData<Loader>();

  useEffect(() => {
    if (data.toast) {
      switch (data.toast.type) {
        case "success":
          toast.success(data.toast.message, { duration: 6000 });
          break;
        case "error":
          toast.error(data.toast.message, { duration: 6000 });
          break;
      }
    }
  }, [data.toast]);

  return <Toaster {...props} />;
}
